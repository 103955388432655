import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

// Şirket listesi
const companyKeywords = {
  "Ankara Sigorta": ["ankara anonim türk sigorta şirket", "ankara sigorta a.ş", "ankara sigorta", "ankara"],
  "Ak Sigorta": ["ak sigorta a.ş", "ak sigorta anonim şirketi", "aksigorta anonim şirketi", "ak sigorta", "aksigorta"],
  "Sompo Sigorta": ["sompo", "asıl sompo", "sompo japan", "sompo sigorta a.ş", "sompo sigorta anonim şirketi"],
  "Neova Sigorta": ["neova", "neova sigorta a.ş", "neova sigorta"],
  "Unico Sigorta": ["unico", "unico sigorta a.ş", "unico sigorta"],
  "GNC Yol Yardım": ["gnc", "gnc assist yardım ve destek hizmetleri danışmanlık a.ş", "gnc assist"],
  "Quick Sigorta": ["quick", "quick sigorta a.ş", "quick sigorta"],
  "Ray Sigorta": ["ray", "ray sigorta a.ş", "ray sigorta"],
  "Magdeburger Sigorta": ["magdeburger", "magdeburger sigorta a.ş", "magdeburger sigorta"],
  "Hdi Sigorta": ["hdi", "hdi sigorta", "hdi sigorta a.ş"],
  "Mapfre Sigorta": ["mapfre", "mapfre sigorta a.ş", "mapfre sigorta"],
  "Ana Sigorta": ["ana sigorta", "ana", "ana sigorta a.ş"],
  "Hepiyi Sigorta": ["hep iyi", "hepiyi", "hepiyi sigorta a.ş", "hepiyi sigorta"],
  "Türk Nippon Sigorta": ["türk nippon", "türk nippon sigorta a.ş", "türk nippon sigorta"],
  "Koru Sigorta": ["koru", "koru sigorta a.ş", "koru sigorta"],
  "Axa Sigorta": ["axa", "axa sigorta a.ş", "axa sigorta"],
  "Anadolu Sigorta": ["anadolu", "anadolu sigorta a.ş", "anadolu sigorta"],
  "Allianz Sigorta": ["allianz sigorta a.ş", "allianz", "allianz sigorta"],
  "Türkiye Sigorta": ["türkiye sigorta a.ş", "türkiye", "türkiye sigorta"],
  "Doğa Sigorta": ["Doğa sigorta a.ş", "doğa", "DOGA SİGORTA A.Ş", "Doğa Sigorta A.Ş."],
  "ACNTurk Sigorta": ["ACN", "ACNTurk", "ACN TÜRK", "ACNTÜRK"]
};

const productFields = [
  { key: 'trafik', label: 'Trafik Sigortası' },
  { key: 'kasko', label: 'Kasko' },
  { key: 'konut', label: 'Konut Sigortası' },
  { key: 'yol_yardim', label: 'Yol Yardım' },
  { key: 'imm', label: 'İMM' },
  { key: 'isyeri', label: 'İşyeri Sigortası' },
  { key: 'saglik', label: 'Sağlık Sigortası' },
  { key: 'dask', label: 'DASK' }
];

const months = [
  { value: 1, label: 'Ocak' },
  { value: 2, label: 'Şubat' },
  { value: 3, label: 'Mart' },
  { value: 4, label: 'Nisan' },
  { value: 5, label: 'Mayıs' },
  { value: 6, label: 'Haziran' },
  { value: 7, label: 'Temmuz' },
  { value: 8, label: 'Ağustos' },
  { value: 9, label: 'Eylül' },
  { value: 10, label: 'Ekim' },
  { value: 11, label: 'Kasım' },
  { value: 12, label: 'Aralık' }
];

const PastPoliciesForm = () => {
  const [selectedCompany, setSelectedCompany] = useState('');
  const [selectedMonth, setSelectedMonth] = useState(new Date().getMonth());
  const [year, setYear] = useState(new Date().getFullYear() - 1);
  const [formData, setFormData] = useState(
    productFields.reduce((acc, { key }) => ({
      ...acc,
      [`${key}_count`]: '',
      [`${key}_amount`]: ''
    }), {})
  );
  const [isAdmin, setIsAdmin] = useState(false);
  const [existingData, setExistingData] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const checkAdmin = async () => {
      try {
        const response = await axios.get('https://centalyayanimda.com/check_session.php');
        const { authenticated, role } = response.data;
        if (authenticated && role === 'admin') {
          setIsAdmin(true);
        } else {
          navigate('/');
        }
      } catch (error) {
        console.error('Yetki kontrolü sırasında bir hata oluştu:', error);
        navigate('/');
      }
    };

    checkAdmin();
  }, [navigate]);

  // Seçilen şirket, ay ve yıl için mevcut veriyi kontrol et
  useEffect(() => {
    if (selectedCompany && year) {
      const fetchExistingData = async () => {
        try {
          const response = await axios.get(
            `https://centalyayanimda.com/get_past_policy.php?company=${selectedCompany}&year=${year}&month=${selectedMonth + 1}`
          );
          if (response.data.success && response.data.data) {
            setExistingData(response.data.data);
            // Form verilerini mevcut verilerle doldur
            setFormData(response.data.data);
          } else {
            setExistingData(null);
            // Form verilerini sıfırla
            setFormData(
              productFields.reduce((acc, { key }) => ({
                ...acc,
                [`${key}_count`]: '',
                [`${key}_amount`]: ''
              }), {})
            );
          }
        } catch (error) {
          console.error('Mevcut veri kontrolünde hata:', error);
        }
      };

      fetchExistingData();
    }
  }, [selectedCompany, year, selectedMonth]);

  const handleInputChange = (key, type, value) => {
    setFormData(prev => ({
      ...prev,
      [`${key}_${type}`]: value
    }));
  };
  
  const handleSubmit = async (e) => {
    e.preventDefault();

    // Boş string değerleri 0'a çevir
    const processedFormData = Object.entries(formData).reduce((acc, [key, value]) => ({
      ...acc,
      [key]: value === '' ? '0' : value
    }), {});

    try {
      const response = await axios.post(
        'https://centalyayanimda.com/past_policies_add.php',
        {
          company_name: selectedCompany,
          year: year,
          month: selectedMonth + 1,
          ...processedFormData
        },
        {
          withCredentials: true,
          headers: {
            'Content-Type': 'application/json',
          }
        }
      );

      if (response.data.success) {
        alert('Geçmiş poliçe verileri başarıyla kaydedildi.');
        setSelectedCompany('');
        setFormData(
          productFields.reduce((acc, { key }) => ({
            ...acc,
            [`${key}_count`]: '',
            [`${key}_amount`]: ''
          }), {})
        );
      } else {
        alert('Veriler kaydedilirken bir hata oluştu: ' + response.data.message);
      }
    } catch (error) {
      console.error('Veriler kaydedilirken bir hata oluştu:', error);
      const errorMessage = error.response?.data?.message || error.message;
      alert('Veriler kaydedilirken bir hata oluştu: ' + errorMessage);
    }
  };

  return (
    <div className="container mx-auto p-6">
      <h2 className="text-3xl font-bold mb-6 text-gray-800">Geçmiş Dönem Poliçe Girişi</h2>
      
      {existingData && (
        <div className="mb-6 p-4 bg-yellow-50 border-l-4 border-yellow-400 rounded">
          <p className="text-yellow-700">
            Bu dönem için mevcut veri bulunmaktadır. Değişiklik yaparsanız veriler güncellenecektir.
          </p>
        </div>
      )}
      
      <form onSubmit={handleSubmit} className="bg-white shadow-md rounded-lg p-6">
        <div className="grid grid-cols-1 md:grid-cols-3 gap-6 mb-6">
          <div>
            <label className="block text-gray-700 text-sm font-bold mb-2">Şirket Seçin</label>
            <select
              value={selectedCompany}
              onChange={(e) => setSelectedCompany(e.target.value)}
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              required
            >
              <option value="">Şirket Seçin</option>
              {Object.keys(companyKeywords).map((company) => (
                <option key={company} value={company}>
                  {company}
                </option>
              ))}
            </select>
          </div>

          <div>
            <label className="block text-gray-700 text-sm font-bold mb-2">Ay</label>
            <select
              value={selectedMonth}
              onChange={(e) => setSelectedMonth(Number(e.target.value))}
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              required
            >
              {months.map((month, index) => (
                <option key={month.value} value={index}>
                  {month.label}
                </option>
              ))}
            </select>
          </div>

          <div>
            <label className="block text-gray-700 text-sm font-bold mb-2">Yıl</label>
            <select
              value={year}
              onChange={(e) => setYear(Number(e.target.value))}
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              required
            >
              {Array.from({ length: 5 }, (_, i) => new Date().getFullYear() - i - 1).map(year => (
                <option key={year} value={year}>{year}</option>
              ))}
            </select>
          </div>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
          {productFields.map(({ key, label }) => (
            <div key={key} className="bg-gray-50 p-4 rounded-lg shadow-sm">
              <h3 className="text-lg font-semibold mb-4 text-blue-600">{label}</h3>
              <div className="space-y-4">
                <div>
                  <label className="block text-gray-700 text-sm font-bold mb-2">
                    Adet
                  </label>
                  <input
                    type="number"
                    min="0"
                    value={formData[`${key}_count`]}
                    onChange={(e) => handleInputChange(key, 'count', e.target.value)}
                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  />
                </div>
                <div>
                  <label className="block text-gray-700 text-sm font-bold mb-2">
                    Tutar (TL)
                  </label>
                  <input
                    type="number"
                    min="0"
                    step="0.01"
                    value={formData[`${key}_amount`]}
                    onChange={(e) => handleInputChange(key, 'amount', e.target.value)}
                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  />
                </div>
              </div>
            </div>
          ))}
        </div>

        <div className="mt-6">
          <button
            type="submit"
            className="w-full bg-blue-500 hover:bg-blue-700 text-white font-bold py-3 px-4 rounded focus:outline-none focus:shadow-outline"
          >
            {existingData ? 'Güncelle' : 'Kaydet'}
          </button>
        </div>
      </form>
    </div>
  );
};

export default PastPoliciesForm;