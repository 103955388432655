import React, { useMemo, useState, useEffect } from 'react';
import { useTable, useFilters, useSortBy, usePagination } from 'react-table';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { FaMedal } from 'react-icons/fa';
import SalesFilter from './SalesFilter';
import ProductionCharts from './ProductionCharts';



const AdminSalesPage = () => {
  const navigate = useNavigate();
  const [isAuthorized, setIsAuthorized] = useState(false);
  const [salesData, setSalesData] = useState([]);
  const [dailyTotalGross, setDailyTotalGross] = useState(0);
  const [dailyRanking, setDailyRanking] = useState([]);
  const [monthlyTotalGross, setMonthlyTotalGross] = useState(0);
  const [monthlyRanking, setMonthlyRanking] = useState([]);
  const [filters, setFilters] = useState({
    personnel_id: '',
    company_name: '',
    product_name: '',
    startDate: '',
    endDate: '',
  });
  const [companies, setCompanies] = useState([]);
  const [products, setProducts] = useState([]);
  
  const personnelData = {
    5: 'Busra Usta',
    6: 'Sude Akcay',
    7: 'Baran Atalay',
    8: 'Kunduzhon Gaipova',
    10: 'Koray',
    11: 'Elifnur Keskin',
    12: 'Koray Atalay',


  };

  // Tablo için kolon tanımları - Hook'ları en üste taşıdık
  const columns = useMemo(
    () => [
      { Header: 'Satış ID', accessor: 'id' },
      { Header: 'Personel Adı', accessor: 'personnel_id', Cell: ({ value }) => personnelData[value] || value },
      { Header: 'Şirket Adı', accessor: 'company_name' },
      { Header: 'Ürün Adı', accessor: 'product_name' },
      { Header: 'Net Prim', accessor: 'net_premium', Cell: ({ value }) => formatCurrency(value / 100) },
      { Header: 'Brüt Prim', accessor: 'gross_premium', Cell: ({ value }) => formatCurrency(value / 100) },
      { Header: 'Başlangıç Tarihi', accessor: 'policy_start_date' },
      { Header: 'Bitiş Tarihi', accessor: 'policy_end_date' },
    ],
    []
  );

  // useTable hook'unu da en üste taşıdık
  const tableInstance = useTable(
    {
      columns,
      data: salesData,
      initialState: { pageIndex: 0 },
    },
    useFilters,
    useSortBy,
    usePagination
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    gotoPage,
    nextPage,
    previousPage,
    state: { pageIndex },
  } = tableInstance;

  // Yetki kontrolü useEffect'i en başta
  useEffect(() => {
    const checkAdminAuth = async () => {
      try {
        const response = await fetch('https://centalyayanimda.com/check_session.php', {
          credentials: 'include'
        });
        const data = await response.json();
        
        if (!data.authenticated || data.role !== 'admin') {
          navigate('/login');
          return;
        }
        
        setIsAuthorized(true);
      } catch (error) {
        console.error('Yetki kontrolü hatası:', error);
        navigate('/login');
      }
    };

    checkAdminAuth();
  }, [navigate]);

  // Veri yükleme useEffect'i
  useEffect(() => {
    if (isAuthorized) {
      fetchSalesData();
      fetchFilterOptions();
      fetchDashboardData();
    }
  }, [isAuthorized]);

  // Filter değişikliği useEffect'i
  useEffect(() => {
    if (isAuthorized && Object.values(filters).some(value => value !== '')) {
      fetchFilteredData();
    }
  }, [filters, isAuthorized]);

  const fetchSalesData = async () => {
    try {
      const response = await axios.post('https://centalyayanimda.com/admin_sales.php', filters);
      if (response.data.success) {
        setSalesData(response.data.sales);
        calculateProductionData(response.data.sales);
      } else {
        alert(response.data.message);
      }
    } catch (error) {
      console.error('Satış verileri çekilirken hata oluştu:', error);
    }
  };

  const fetchDashboardData = async () => {
    try {
      const response = await axios.get('admin_sales.php'); // Filtresiz veri için GET isteği
      if (response.data.success) {
        calculateProductionData(response.data.sales);
      } else {
        alert(response.data.message);
      }
    } catch (error) {
      console.error('Dashboard verileri çekilirken hata oluştu:', error);
    }
  };

  // Satış verilerini backend'den çekme fonksiyonu
  // Üstteki 4 tablo için veri çekme (filtresiz, sadece güncel veriler)
  const fetchFilterOptions = async () => {
    try {
      const response = await axios.get('admin_sales.php');
      if (response.data.success) {
        setCompanies(response.data.companies);
        setProducts(response.data.products);
      } else {
        alert('Filtreleme seçenekleri yüklenirken hata oluştu');
      }
    } catch (error) {
      console.error('Filtreleme seçenekleri çekilirken hata oluştu:', error);
    }
  };

// Filtreleme için veri çekme (alttaki tablo için)
const fetchFilteredData = async () => {
  try {
    const response = await axios.post('sales_filter.php', filters);
    if (response.data.success) {
      setSalesData(response.data.sales);
    } else {
      alert(response.data.message);
    }
  } catch (error) {
    console.error('Filtrelenmiş veriler çekilirken hata oluştu:', error);
  }
};

// useEffect'i düzeltelim
useEffect(() => {
  fetchDashboardData(); // Üst tablolar için
  fetchFilterOptions(); // Filtre seçenekleri için
}, []); // Sadece sayfa yüklendiğinde çalışsın

useEffect(() => {
  if (Object.values(filters).some(value => value !== '')) {
    fetchFilteredData(); // Filtreler değiştiğinde çalışsın
  }
}, [filters]);

  // Üretim verilerini hesaplama fonksiyonu
  const calculateProductionData = (sales) => {
    let dailyTotal = 0;
    let monthlyTotal = 0;
    const dailyRank = {};
    const monthlyRank = {};

    const today = new Date().toISOString().slice(0, 10);
    const currentMonth = new Date().getMonth() + 1;

    sales.forEach((sale) => {
      const saleDate = new Date(sale.created_at); // Kayıt tarihini kullan
      const saleMonth = saleDate.getMonth() + 1;
      const grossPremium = parseFloat(sale.gross_premium) / 100; // Sondaki iki sıfırı sayma

      // Günlük toplam brüt üretim ve sıralama
      if (saleDate.toISOString().slice(0, 10) === today) {
        dailyTotal += grossPremium;
        dailyRank[sale.personnel_id] = (dailyRank[sale.personnel_id] || 0) + grossPremium;
      }

      // Aylık toplam brüt üretim ve sıralama
      if (saleMonth === currentMonth) {
        monthlyTotal += grossPremium;
        monthlyRank[sale.personnel_id] = (monthlyRank[sale.personnel_id] || 0) + grossPremium;
      }
    });

    setDailyTotalGross(dailyTotal);
    setMonthlyTotalGross(monthlyTotal);

    const dailyRankingArray = Object.entries(dailyRank).map(([id, total]) => ({ name: personnelData[id], total }));
    dailyRankingArray.sort((a, b) => b.total - a.total);
    setDailyRanking(dailyRankingArray);

    const monthlyRankingArray = Object.entries(monthlyRank).map(([id, total]) => ({ name: personnelData[id], total }));
    monthlyRankingArray.sort((a, b) => b.total - a.total);
    setMonthlyRanking(monthlyRankingArray);
  };

  // Rakamları Avrupa para birimi formatında gösterme fonksiyonu
  const formatCurrency = (value) => {
    return value.toLocaleString('tr-TR', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) + ' TL';
  };



  // Filtreleme değişikliğini yönetme
  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    setFilters({
      ...filters,
      [name]: value,
    });
  };

  return (
    <div className="admin-sales-page p-8 bg-gray-100 min-h-screen">
      <h1 className="text-4xl font-extrabold text-gray-800 mb-8 text-center">Admin Performans Takip Sayfası</h1>

      {/* Üst kartlar grid yapısı */}
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6 mb-8">
        {/* Günlük Toplam Brüt Üretim Tutarı */}
        <div className="bg-white shadow-md rounded-lg p-6 transform hover:scale-105 transition-transform duration-300">
          <h2 className="text-2xl font-bold mb-4 text-blue-800">Günlük Toplam Brüt Üretim Tutarı</h2>
          <p className="text-3xl text-gray-900 font-semibold">{formatCurrency(dailyTotalGross)}</p>
        </div>

        {/* Günlük Üretim Tablosunun Personele Göre Sıralaması */}
        <div className="bg-white shadow-md rounded-lg p-6 transform hover:scale-105 transition-transform duration-300">
          <h2 className="text-2xl font-bold mb-4 text-green-800">Günlük Üretim Sıralaması (Personel)</h2>
          <ul className="list-none ml-0">
            {dailyRanking.map((person, index) => (
              <li key={index} className="flex items-center mb-2">
                <span className="mr-2">
                  {index + 1 === 1 && <FaMedal className="text-yellow-500" />}
                  {index + 1 === 2 && <FaMedal className="text-gray-500" />}
                  {index + 1 === 3 && <FaMedal className="text-orange-500" />}
                  {index + 1 > 3 && <span>{index + 1}</span>}
                </span>
                {person.name} - <span className="ml-2 font-semibold">{formatCurrency(person.total)}</span>
              </li>
            ))}
          </ul>
        </div>

        {/* Aylık Toplam Üretim */}
        <div className="bg-white shadow-md rounded-lg p-6 transform hover:scale-105 transition-transform duration-300">
          <h2 className="text-2xl font-bold mb-4 text-purple-800">Aylık Toplam Üretim</h2>
          <p className="text-3xl text-gray-900 font-semibold">{formatCurrency(monthlyTotalGross)}</p>
        </div>

        {/* Aylık Üretim Sıralaması Personele Göre */}
        <div className="bg-white shadow-md rounded-lg p-6 transform hover:scale-105 transition-transform duration-300">
          <h2 className="text-2xl font-bold mb-4 text-red-800">Aylık Üretim Sıralaması (Personel)</h2>
          <ul className="list-none ml-0">
            {monthlyRanking.map((person, index) => (
              <li key={index} className="flex items-center mb-2">
                <span className="mr-2">
                  {index + 1 === 1 && <FaMedal className="text-yellow-500" />}
                  {index + 1 === 2 && <FaMedal className="text-gray-500" />}
                  {index + 1 === 3 && <FaMedal className="text-orange-500" />}
                  {index + 1 > 3 && <span>{index + 1}</span>}
                </span>
                {person.name} - <span className="ml-2 font-semibold">{formatCurrency(person.total)}</span>
              </li>
            ))}
          </ul>
        </div>
      </div>

      <ProductionCharts salesData={salesData} personnelData={personnelData} />


      {/* Yeni Filtreleme Komponenti */}
      <SalesFilter
  filters={filters}
  setFilters={setFilters}
  companies={companies}
  products={products}
  personnelData={personnelData}
  onFilter={fetchFilteredData}  // fetchSalesData yerine fetchFilteredData
  filteredData={salesData}
/>
    </div>
  );
};

export default AdminSalesPage;
