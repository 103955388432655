import React, { useState, useEffect } from 'react';
import * as pdfjsLib from 'pdfjs-dist';
import axios from 'axios';
import "tailwindcss/tailwind.css";
import { FaDollarSign, FaLightbulb } from 'react-icons/fa';
import { AiOutlineLoading3Quarters } from 'react-icons/ai';
import { CheckCircle2, XCircle } from 'lucide-react';

// pdf.js işçi dosyasını tanımla
pdfjsLib.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjsLib.version}/pdf.worker.min.js`;



const DidYouKnow = () => {
  const facts = [
    "Türkiye'de trafik sigortası 1953 yılında zorunlu hale getirilmiştir.",
    "Kasko sigortası, aracınızı doğal afetlere karşı da korur.",
    "DASK, 1999 Marmara Depremi'nden sonra zorunlu hale getirilmiştir.",
    "Sağlık sigortası, koruyucu sağlık hizmetlerini de kapsar.",
    "Ferdi kaza sigortası, 7/24 dünya çapında geçerlidir.",
    "Konut sigortası, evinizin içindeki eşyaları da koruma altına alır.",
    "İMM sigortası, trafik sigortasının tamamlayıcısı niteliğindedir."
  ];

  

  const [fact, setFact] = useState('');

  useEffect(() => {
    setFact(facts[Math.floor(Math.random() * facts.length)]);
  }, []);

  return (
    <div className="bg-blue-100 border-l-4 border-blue-500 text-blue-700 p-4 mb-4 rounded-lg shadow-md">
      <div className="flex items-center">
        <FaLightbulb className="text-yellow-400 mr-2" size={24} />
        <p className="font-bold">Bunu biliyor muydunuz?</p>
      </div>
      <p className="mt-2">{fact}</p>
    </div>
  );
};

const LoadingSpinner = () => (
  <div className="fixed top-0 left-0 right-0 bottom-0 w-full h-screen z-50 overflow-hidden bg-gray-700 opacity-75 flex flex-col items-center justify-center">
    <AiOutlineLoading3Quarters className="animate-spin text-white text-4xl mb-4" />
    <h2 className="text-center text-white text-xl font-semibold">Yapay zeka poliçe bilgilerini çıkarıyor...</h2>
    <p className="w-1/3 text-center text-white">Bu işlem birkaç saniye sürebilir, lütfen bekleyin.</p>
  </div>
);

function normalizeText(text) {
  return text.toLowerCase()
    .replace(/\s+/g, ' ')
    .trim()
    .normalize("NFD").replace(/[\u0300-\u036f]/g, "");
}

// Anahtar kelime eşleştirme tablosu
const urunAnahtarKelimeleri = {
  "Trafik Sigortası": ["trafik", "zorunlu trafik", "ZORUNLU MALİ SORUMLULUK", "karayolları motorlu araçlar", "zorunlu mali sorumluluk"],
  "Kasko": ["kasko", "tam kasko", "genişletilmiş kasko"],
  "Konut Sigortası": ["yuvam", "konutum", "evim", "konut", "Allianz Yuvam"],
  "Sağlık Sigortası": ["sağlık", "tamamlayıcı sağlık", "TAMAMLAYICI SAĞLIK SİGORTA POLİÇESİ", "FARK YOK TAMAMLAYICI SAĞLIK SİGORTASI"],
  "Dask": ["Deprem", "Afet"],
  "İMM": ["İhtiyari Mali Sorumluluk", "İMM"],
  "Ferdi Kaza & Yol Yardım": ["ARAÇ YOL YARDIM SİGORTASI", "ACİL SAĞLIK SİGORTA POLİÇESİ", "yol yardım", "yol yardım plus", "PLUS", "ferdi kaza"],
  "İsyeri": ["işyeri", "kobi", "işyeri yangın"],

};

// Ürün adını standartlaştırma fonksiyonu
function standartlastirUrunAdi(urunAdi) {
  const normalizedUrunAdi = normalizeText(urunAdi);
  for (const [standartAd, anahtarKelimeler] of Object.entries(urunAnahtarKelimeleri)) {
    if (anahtarKelimeler.some(kelime => normalizedUrunAdi.includes(normalizeText(kelime)))) {
      return standartAd;
    }
  }
  return urunAdi;
}

// Şirket adlarını standartlaştırmak için anahtar kelime eşleştirme tablosu
const companyKeywords = {
  "Ankara Sigorta": ["ankara anonim türk sigorta şirket", "ankara sigorta a.ş"],
  "Ak Sigorta": ["ak sigorta a.ş", "ak sigorta anonim şirketi", "AKSİGORTA ANONİM ŞİRKETİ"],
  "Sompo Sigorta": ["sompo", "asıl sompo", "sompo japan"],
  "Neova Sigorta": ["neova"],
  "Unico Sigorta": ["unico"],
  "GNC Yol Yardım": ["GNC", "GNC ASSIST YARDIM VE DESTEK HİZMETLERİ DANIŞMANLIK A.Ş."],
  "Quick Sigorta": ["Quick"],
  "Ray Sigorta": ["Ray"],
  "Magdeburger Sigorta": ["magdeburger"],
  "Hdi Sigorta": ["HDI", "HDİ", "HDI Sigorta"],
  "Mapfre Sigorta": ["mapfre"],
  "Ana Sigorta": ["ana sigorta", "ana"],
  "Hepiyi Sigorta": ["Hep iyi", "Hepiyi"],
  "Türk Nippon Sigorta": ["türk nippon"],
  "Koru Sigorta": ["koru"],
  "Axa Sigorta": ["axa"],
  "Anadolu Sigorta": ["anadolu"],
  "Allianz Sigorta": ["Allianz Sigorta A.Ş.", "allianz"],
  "Türkiye Sigorta": ["Türkiye Sigorta A.Ş.", "Türkiye"],
  "Türkiye Katılım Sigorta": ["Türkiye Katılım Sigorta A.Ş.", "TÜRKİYE KATILIM SİGORTA A.Ş."],
  "Atlas Sigorta": ["ATLAS SİGORTA KOOPERATİFİ", "atlas"],
  "Doğa Sigorta": ["Doğa sigorta", "doğa", "Doğa Sigorta A.Ş."],
  
  

  // Diğer şirket adlarını burada ekleyebilirsiniz
};

// Şirket adını standartlaştırma fonksiyonu
function standartlastirSirketAdi(sirketAdi) {
  const normalizedSirketAdi = normalizeText(sirketAdi);
  for (const [standartAd, anahtarKelimeler] of Object.entries(companyKeywords)) {
    if (anahtarKelimeler.some(kelime => normalizedSirketAdi.includes(normalizeText(kelime)))) {
      return standartAd;
    }
  }
  return sirketAdi;
}

const PDFReader = () => {
  const [extractedData, setExtractedData] = useState(null);
  const [dragActive, setDragActive] = useState(false);
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState('');
  const [newOrRenewal, setNewOrRenewal] = useState('');
  const [commission, setCommission] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isExtracting, setIsExtracting] = useState(false);
  const [isSaved, setIsSaved] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [editField, setEditField] = useState(null);
  const [editValue, setEditValue] = useState('');

  const isValidCompany = (companyName) => {
    return Object.keys(companyKeywords).includes(companyName);
  };

  const isValidProduct = (productName) => {
    return Object.keys(urunAnahtarKelimeleri).includes(productName);
  };

  // Validation ikonu komponenti
  const ValidationIcon = ({ isValid, fieldName }) => {
    if (isValid === null) return null;
    
    return isValid ? (
      <CheckCircle2 className="w-5 h-5 text-green-500 ml-2" />
    ) : (
      <div className="flex items-center">
        <XCircle className="w-5 h-5 text-red-500 ml-2" />
        <span className="text-xs text-red-500 ml-1">
          Standart {fieldName} listesinde yok
        </span>
      </div>
    );
  };

  // Düzenlenmiş veri gösterimi komponenti
  const DataField = ({ label, value, type }) => {
    let isValid = null;
    
    if (type === 'company') {
      isValid = isValidCompany(value);
    } else if (type === 'product') {
      isValid = isValidProduct(value);
    }

    return (
      <div className="mb-2 flex items-center">
        <div className="flex-1">
          <span className="font-semibold">{label}:</span>
          <span className={`ml-2 ${!isValid && (type === 'company' || type === 'product') ? 'text-red-500' : ''}`}>
            {value}
          </span>
        </div>
        {(type === 'company' || type === 'product') && (
          <ValidationIcon 
            isValid={isValid} 
            fieldName={type === 'company' ? 'şirket' : 'ürün'}
          />
        )}
        <button
          onClick={() => startEditing(label)}
          className="ml-2 text-blue-500 hover:text-blue-700"
        >
          Düzenle
        </button>
      </div>
    );
  };



  // Bu fonksiyonları bileşen içine taşıyoruz
  const startEditing = (field) => {
    setIsEditing(true);
    setEditField(field);
  };

  const stopEditing = () => {
    setIsEditing(false);
    setEditField(null);
    setEditValue('');
  };

  const handleEdit = (field, value) => {
    setExtractedData(prevData => ({
      ...prevData,
      [field]: value
    }));
  };

  useEffect(() => {
    if (isEditing && editField) {
      setEditValue(extractedData[editField] || '');
    }
  }, [isEditing, editField, extractedData]);

  // Bu değişkenleri useMemo ile optimize edebiliriz
  const companyList = React.useMemo(() => Object.keys(companyKeywords), []);
  const productList = React.useMemo(() => Object.keys(urunAnahtarKelimeleri), []);

  // Komisyon oranları doğru
const commissionRates = {
  'Trafik Sigortası': { new: 0.0002, renewal: 0.0001, company: 0.0001 },
  'Kasko': { new: 0.005, renewal: 0.0015, company: 0.0015 },
  'Konut Sigortası': { new: 0.020, renewal: 0.005, company: 0.005 },
  'Sağlık Sigortası': { new: 0.01, renewal: 0.003, company: 0.003 },
  'Dask': { new: 0.003, renewal: 0.003, company: 0.003 },
  'Ferdi Kaza & Yol Yardım': { new: 0.01, renewal: 0.01, company: 0.01 },
  'İMM': { new: 0.005, renewal: 0.003, company: 0.002 },
  'İsyeri': { new: 0.01, renewal: 0.003, company: 0.003 },

};  

  function parseMoneyValue(value) {
    if (typeof value !== 'string') return 0;

    // Boşlukları kaldır
    value = value.replace(/\s/g, '');

    // Sayı olmayan karakterleri kaldır (rakamlar, nokta ve virgül hariç)
    value = value.replace(/[^\d.,]/g, '');

    // Hem virgül hem nokta içeriyor mu?
    const commaIndex = value.indexOf(',');
    const dotIndex = value.indexOf('.');

    if (commaIndex > -1 && dotIndex > -1) {
      if (commaIndex < dotIndex) {
        // '1,234.56' formatı (Amerikan/İngiliz)
        value = value.replace(/,/g, '');
        return parseFloat(value) || 0;
      } else {
        // '1.234,56' formatı (Avrupa/Türk)
        value = value.replace(/\./g, '');
        value = value.replace(',', '.');
        return parseFloat(value) || 0;
      }
    } else if (dotIndex > -1) {
      if (value.split('.').length > 2) {
        // Birden fazla nokta varsa, noktalar binlik ayırıcıdır
        value = value.replace(/\./g, '');
        return parseFloat(value) || 0;
      } else {
        // Tek nokta varsa, ondalık ayırıcı olabilir
        return parseFloat(value) || 0;
      }
    } else if (commaIndex > -1) {
      if (value.split(',').length > 2) {
        // Birden fazla virgül varsa, virgüller binlik ayırıcıdır
        value = value.replace(/,/g, '');
        return parseFloat(value) || 0;
      } else {
        // Tek virgül varsa, ondalık ayırıcı olabilir
        value = value.replace(',', '.');
        return parseFloat(value) || 0;
      }
    } else {
      // Ne virgül ne nokta varsa, doğrudan parse et
      return parseFloat(value) || 0;
    }
  }

const calculateCommission = (productName, grossPremium, option) => {
  const rates = commissionRates[productName];
  if (!rates) return 0;
  
  let rate;
  switch(option) {
    case 'Yeni İş':
      rate = rates.new;
      break;
    case 'Yenileme':
      rate = rates.renewal;
      break;
    case 'Şirket Üretimi':
      rate = rates.company;
      break;
    default:
      rate = 0;
  }
  
  return grossPremium * rate;
};

  const extractDataFromPDF = async (pdfFile) => {
    setLoading(true);
    setIsExtracting(true);
    try {
      const fileReader = new FileReader();
      fileReader.onload = async function () {
        const typedarray = new Uint8Array(this.result);
        const pdf = await pdfjsLib.getDocument(typedarray).promise;

        let text = "";

        for (let i = 1; i <= Math.min(3, pdf.numPages); i++) {
          const page = await pdf.getPage(i);
          const textContent = await page.getTextContent();
          textContent.items.forEach((item) => {
            text += item.str + " ";
          });
        }

        const apiKey = process.env.REACT_APP_OPENAI_API_KEY;

        if (!apiKey) {
          console.error("API anahtarı bulunamadı.");
          return;
        }

        let retries = 3;

        while (retries > 0) {
          try {
            const response = await axios.post(
              'https://api.openai.com/v1/chat/completions',
              {
                model: 'gpt-3.5-turbo',
                messages: [
                  {
                    role: 'user',
                    content: `Aşağıdaki PDF metninden şu bilgileri çıkar: Şirket Adı, Poliçe Numarası, Ürün Adı, Poliçe Net Primi, Poliçe Brüt Primi, Poliçe Başlangıç ve Bitiş Tarihi.
                    primleri çıkarırken basamakları şu formatta yaz. x.xxx,xx veya xx.xxx,xx

                    Ayrıca, Net Prim ve Brüt Prim şu şekilde ifade edilebilir:
- Net Prim: NET PRİM:, net prim:, Net Prim, toplam net prim, 
- Brüt Prim: Poliçe Primi, Ödenecek Brüt Tutar, Ödenecek Toplam Tutar, Ödenecek Tutar, Brüt Tutar, Brüt Prim, toplam

ÖNEMLİ : ! Net prim ve brüt prim kesinlikle şu şekilde ifade edilemez: "sigorta bedeli" veya "SİGORTA BEDELİ"
                    
                    Önemli: Şirket Adı olarak asla şu isimleri verme: SİGORTAMOLSUN, SİGORTAMOLSUN SİGORTA ARAC. HİZM. LTD. ŞTİ., CENTALYA, CENTALYA SİGORTA, SİGORTAMEMTALYA, KOBİ SİGORTA. Bunlar aracı kurum isimleridir, sigorta şirketi değildir.
Sigorta şirketlerinin ismini genelde pdf'in sağ üst veya sol üst köşelerinde logolarından tanıyabilirsin.

                   \n\n${text}`
                  },
                ],
                max_tokens: 300,
                temperature: 0.2,
              },
              {
                headers: {
                  'Content-Type': 'application/json',
                  'Authorization': `Bearer ${apiKey}`,
                },
              }
            );
            const content = response.data.choices[0].message.content;
            let parsedData;
            try {
              const lines = content.split('\n');
              parsedData = {};
              lines.forEach(line => {
                const [key, value] = line.split(': ');
                if (key && value) {
                  parsedData[key.trim()] = value.trim();
                }
              });

              const invalidCompanyNames = [
                "SİGORTAMOLSUN", "SİGORTAMOLSUN SİGORTA ARAC. HİZM. LTD. ŞTİ.",
                "CENTALYA", "CENTALYA SİGORTA", "SİGORTAMEMTALYA", "KOBİ SİGORTA"
              ];

              if (parsedData['Şirket Adı'] && invalidCompanyNames.some(name => parsedData['Şirket Adı'].includes(name))) {
                console.warn("Geçersiz şirket adı tespit edildi:", parsedData['Şirket Adı']);
                parsedData['Şirket Adı'] = "Bilinmeyen Sigorta Şirketi";
              }

              if (parsedData['Ürün Adı']) {
                parsedData['Ürün Adı'] = standartlastirUrunAdi(parsedData['Ürün Adı']);
              }

              if (parsedData['Şirket Adı']) {
                parsedData['Şirket Adı'] = standartlastirSirketAdi(parsedData['Şirket Adı']);
              }

              if (parsedData['Poliçe Başlangıç Tarihi']) {
                parsedData['Poliçe Başlangıç Tarihi'] = parsedData['Poliçe Başlangıç Tarihi'].split(' ')[0];
              }
              
              // Poliçe Bitiş Tarihi'nden saat bilgisini kaldır
              if (parsedData['Poliçe Bitiş Tarihi']) {
                parsedData['Poliçe Bitiş Tarihi'] = parsedData['Poliçe Bitiş Tarihi'].split(' ')[0];
              }

            } catch (parseError) {
              console.error("Veri işleme hatası:", parseError);
              parsedData = { error: "Veri işlenemedi", rawContent: content };
            }
            setExtractedData(parsedData);

            break;
          } catch (error) {
            console.error("PDF verisi işlenirken hata oluştu:", error);
            setMessage("PDF işlenirken bir hata oluştu.");
          } finally {
            setLoading(false);
            setIsExtracting(false);
          }
        }
      };
      fileReader.readAsArrayBuffer(pdfFile);
    } catch (error) {
      console.error("PDF verisi işlenirken hata oluştu:", error);
      setMessage("PDF işlenirken bir hata oluştu.");
    } finally {
      setLoading(false);
    }
  };

  const handleDrag = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === "dragenter" || e.type === "dragover") {
      setDragActive(true);
    } else if (e.type === "dragleave") {
      setDragActive(false);
    }
  };

  const handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);
    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      extractDataFromPDF(e.dataTransfer.files[0]);
    }
  };

  const handleChange = (e) => {
    if (e.target.files && e.target.files[0]) {
      extractDataFromPDF(e.target.files[0]);
    }
  };

  const handleSave = async () => {
    if (isSubmitting) return;
    
    if (!extractedData || extractedData.error) {
      setMessage("Kaydedilecek geçerli veri bulunamadı.");
      return;
    }

    const companyValid = isValidCompany(extractedData['Şirket Adı']);
    const productValid = isValidProduct(extractedData['Ürün Adı']);

    if (!companyValid || !productValid) {
      setMessage("Hopsss! Lütfen şirket veya ürün adlarını kontrol edin! Doğru olmayan birşeyler var...");
      return;
    }

    if (!newOrRenewal) {
      setMessage("Lütfen yeni iş, yenileme mi, şirket üretimi seçin.");
      return;
    }

    try {
      const grossPremium = parseMoneyValue(extractedData['Poliçe Brüt Primi']);
      const calculatedCommission = calculateCommission(
        extractedData['Ürün Adı'],
        grossPremium,
        newOrRenewal  // Doğrudan seçilen option'ı gönderiyoruz
      );

      const dataToSend = {
        ...extractedData,
        "Yeni mi Yenileme mi": newOrRenewal,
        "commission": calculatedCommission
      };
      
      console.log("Gönderilen veri:", dataToSend);
      const response = await axios.post('https://centalyayanimda.com/save_sale.php', dataToSend);
      console.log("Sunucu yanıtı:", response.data);
      
      if (response.data.success) {
        setCommission(calculatedCommission);
        setMessage('Poliçe başarıyla kaydedildi!');
        setIsSaved(true);
      } else {
        setMessage('Poliçe kaydedilirken bir hata oluştu: ' + (response.data.message || 'Bilinmeyen hata'));
      }
    } catch (error) {
      console.error('Poliçe kaydedilirken bir hata oluştu', error.response ? error.response.data : error);
      setMessage('Poliçe kaydedilirken bir hata oluştu: ' + (error.response ? error.response.data.message : error.message));
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleReset = () => {
    setExtractedData(null);
    setNewOrRenewal('');
    setCommission(null);
    setMessage('');
    setIsSaved(false);
  };

  const handleOptionSelect = (option) => {
    setNewOrRenewal(option);
  };

  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-gray-100">
      {isExtracting && <LoadingSpinner />}

      {!isSaved ? (
        <div
          className={`w-2/3 p-6 border-4 border-dashed rounded-lg bg-white shadow-md transition ${
            dragActive ? "border-blue-500" : "border-gray-300"
          }`}
          onDragEnter={handleDrag}
          onDragLeave={handleDrag}
          onDragOver={handleDrag}
          onDrop={handleDrop}
        >
          <input
            type="file"
            accept="application/pdf"
            onChange={handleChange}
            className="hidden"
            id="input-file-upload"
          />
          <label
            htmlFor="input-file-upload"
            className="flex flex-col items-center justify-center w-full h-full cursor-pointer"
          >
            <div className="text-center">
              <p className="text-lg font-semibold text-gray-700">PDF Yüklemek için tıklayın veya sürükleyip bırakın</p>
              <p className="text-sm text-gray-500">Yalnızca PDF dosyalarını destekler</p>
            </div>
          </label>
        </div>
      ) : (
        <div className="w-2/3">
          <DidYouKnow />
          <button
            onClick={handleReset}
            className="w-full mt-4 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
          >
            Yeni Poliçe Yükle
          </button>
        </div>
      )}

      {loading && <p className="mt-4 text-blue-600">PDF işleniyor...</p>}

      {extractedData && !isSaved && (
        <div className="w-2/3 mt-6 p-4 bg-white rounded-lg shadow-md">
          <h3 className="text-xl font-bold text-gray-800 mb-4">Çıkarılan Bilgiler:</h3>
          {Object.entries(extractedData).map(([key, value]) => (
  <div key={key} className="mb-2 flex items-center">
    <div className="flex-1">
      <span className="font-semibold">{key}:</span>
      <span className="ml-2">{value}</span>
    </div>
    {key === 'Şirket Adı' && (
      <>
        {isValidCompany(value) ? (
          <CheckCircle2 className="w-5 h-5 text-green-500 ml-2" />
        ) : (
          <div className="flex items-center">
            <XCircle className="w-5 h-5 text-red-500 ml-2" />
            <span className="text-xs text-red-500 ml-1">
              Standart şirket listesinde yok
            </span>
          </div>
        )}
      </>
    )}
    {key === 'Ürün Adı' && (
      <>
        {isValidProduct(value) ? (
          <CheckCircle2 className="w-5 h-5 text-green-500 ml-2" />
        ) : (
          <div className="flex items-center">
            <XCircle className="w-5 h-5 text-red-500 ml-2" />
            <span className="text-xs text-red-500 ml-1">
              Standart ürün listesinde yok
            </span>
          </div>
        )}
      </>
    )}
    <button
      onClick={() => startEditing(key)}
      className="ml-2 text-blue-500 hover:text-blue-700"
    >
      Düzenle
    </button>
  </div>
))}
          {isEditing && (
            <div className="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full flex items-center justify-center">
              <div className="bg-white p-5 rounded-lg">
                <h4 className="text-lg font-bold mb-2">{editField} Düzenle</h4>
                {editField === 'Şirket Adı' ? (
                  <select
                    className="w-full p-2 border rounded"
                    value={editValue}
                    onChange={(e) => setEditValue(e.target.value)}
                  >
                    <option value="">Seçiniz</option>
                    {companyList.map((company) => (
                      <option key={company} value={company}>
                        {company}
                      </option>
                    ))}
                  </select>
                ) : editField === 'Ürün Adı' ? (
                  <select
                    className="w-full p-2 border rounded"
                    value={editValue}
                    onChange={(e) => setEditValue(e.target.value)}
                  >
                    <option value="">Seçiniz</option>
                    {productList.map((product) => (
                      <option key={product} value={product}>
                        {product}
                      </option>
                    ))}
                  </select>
                ) : (
                  <input
                    type="text"
                    className="w-full p-2 border rounded"
                    value={editValue}
                    onChange={(e) => setEditValue(e.target.value)}
                  />
                )}
                <div className="mt-4 flex justify-end">
                  <button
                    onClick={() => {
                      handleEdit(editField, editValue);
                      stopEditing();
                    }}
                    className="mr-2 bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded"
                  >
                    Kaydet
                  </button>
                  <button
                    onClick={stopEditing}
                    className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded"
                  >
                    İptal
                  </button>
                </div>
              </div>
            </div>
          )}

          {!newOrRenewal ? (
  <div className="mt-6">
    <p className="mb-4 text-gray-700 font-medium text-center">Bu poliçe yeni iş mi, yenileme mi yoksa şirket üretimi mi?</p>
    <div className="flex flex-wrap justify-center gap-4">
      <button
        onClick={() => handleOptionSelect('Yeni İş')}
        className="flex-1 min-w-[160px] max-w-[200px] bg-emerald-500 hover:bg-emerald-600 text-white font-bold py-3 px-6 rounded-lg shadow-md transform transition-all duration-200 hover:scale-105 focus:outline-none focus:ring-2 focus:ring-emerald-500 focus:ring-opacity-50"
        disabled={isSubmitting}
      >
        Yeni İş
      </button>
      
      <button
        onClick={() => handleOptionSelect('Yenileme')}
        className="flex-1 min-w-[160px] max-w-[200px] bg-blue-500 hover:bg-blue-600 text-white font-bold py-3 px-6 rounded-lg shadow-md transform transition-all duration-200 hover:scale-105 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50"
        disabled={isSubmitting}
      >
        Yenileme
      </button>
      
      <button
        onClick={() => handleOptionSelect('Şirket Üretimi')}
        className="flex-1 min-w-[160px] max-w-[200px] bg-purple-500 hover:bg-purple-600 text-white font-bold py-3 px-6 rounded-lg shadow-md transform transition-all duration-200 hover:scale-105 focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-opacity-50"
        disabled={isSubmitting}
      >
        Şirket Üretimi
      </button>
    </div>
  </div>
) : (
  <button
    onClick={handleSave}
    className={`mt-4 w-full bg-green-500 hover:bg-green-600 text-white font-bold py-3 px-6 rounded-lg shadow-md transform transition-all duration-200 hover:scale-105 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-opacity-50 ${
      isSubmitting ? 'opacity-50 cursor-not-allowed' : ''
    }`}
    disabled={isSubmitting}
  >
    {isSubmitting ? 'Kaydediliyor...' : 'Poliçeyi Kaydet'}
  </button>
)}
        </div>
      )}

      {commission !== null && isSaved && (
        <div className="w-2/3 mt-4 p-4 bg-yellow-100 rounded-lg shadow-md flex items-center">
          <FaDollarSign className="text-green-500 mr-2" size={24} />
          <span className="text-lg font-bold text-green-700">
            Kazandığınız Prim: {commission.toFixed(2)} TL
          </span>
          <p className="ml-4 text-sm text-gray-600">
            Harika iş! Bu satışla hedeflerinize bir adım daha yaklaştınız.
          </p>
        </div>
      )}
      {message && <p className="mt-4 text-green-600">{message}</p>}
    </div>
  );
};

export default PDFReader;
