import React from 'react';
import { useForm, Controller } from 'react-hook-form';
import { jsPDF } from 'jspdf';
import html2pdf from 'html2pdf.js';


const IsyeriTeklif = () => {
  const { control, handleSubmit, watch } = useForm();

  const questions = {
    genelBilgiler: [
      { name: 'tcVergiNo', label: 'TC/Vergi No', type: 'text' },
      { name: 'yetkiliKisi', label: 'Yetkili Kişi', type: 'text' },
      { name: 'yetkiliTel', label: 'Yetkili Kişi Tel No', type: 'text' },
      { name: 'faaliyetKonusu', label: 'Faaliyet Konusu', type: 'text' },
      { name: 'acikAdres', label: 'Açık Adres', type: 'text' },
      { name: 'isyeriDurum', label: 'İşyeri Kira mı/Mülk Sahibi mi?', type: 'radio' },
      { name: 'metrekare', label: 'İşyeri Metrekaresi', type: 'number' },
      { name: 'konutVarMi', label: 'İşyeri Üzerinde Konut Var mı?', type: 'radio' },
      { name: 'bodrumVarMi', label: 'İşyerinin Bodrumu Var mı?', type: 'radio' },
      { name: 'binaKat', label: 'Bina Kaç Katlı', type: 'number' },
      { name: 'binaYas', label: 'Binanın Ortalama Yaşı', type: 'number' },
      { name: 'selRiski', label: 'İşyerini Sel Basma Riski Var mı?', type: 'radio' },
      { name: 'calisanSayisi', label: 'Çalışan Sayısı', type: 'number' },
      { name: 'daskDurum', label: 'İşyeri DASK\'a Tabi mi?', type: 'radio' }
    ],
    guvenlikOnlemleri: [
      { name: 'alarmVarMi', label: 'Alarm Var mı?', type: 'radio' },
      { name: 'alarmAciklama', label: 'Alarm Açıklama', type: 'text', dependency: 'alarmVarMi', showIf: 'Evet' },
      { name: 'kameraVarMi', label: 'Kamera Var mı?', type: 'radio' },
      { name: 'kepenkVarMi', label: 'Kepenk Var mı?', type: 'radio' },
      { name: 'bekciVarMi', label: 'Bekçi Var mı?', type: 'radio' }
    ],
    yanginOnlemleri: [
      { name: 'yanginTupuVarMi', label: 'Yangın Tüpü Var mı?', type: 'radio' },
      { name: 'yanginTupuAciklama', label: 'Yangın Tüpü Açıklama', type: 'text', dependency: 'yanginTupuVarMi', showIf: 'Evet' },
      { name: 'dumaDedektoruVarMi', label: 'Duman Dedektörü Var mı?', type: 'radio' },
      { name: 'sprinklerVarMi', label: 'Sprinkler Var mı?', type: 'radio' },
      { name: 'yanginDolabiVarMi', label: 'Yangın Dolabı Var mı?', type: 'radio' },
      { name: 'yanginAlarmiVarMi', label: 'Yangın Alarmı Var mı?', type: 'radio' },
      { name: 'suDeposuVarMi', label: 'Su Deposu Var mı?', type: 'radio' },
      { name: 'jeneratorVarMi', label: 'Jeneratör Var mı?', type: 'radio' },
      { name: 'jeneratorOtomatikMi', label: 'Elektrik Kesildiğinde Jeneratör Otomatik Devreye Giriyor mu?', type: 'radio', dependency: 'jeneratorVarMi', showIf: 'Evet' },
      { name: 'dolabiSuBesleme', label: 'Yangın Dolabı Suyu Nereden Besleniyor?', type: 'text', dependency: 'yanginDolabiVarMi', showIf: 'Evet' },
      { name: 'gunesEnerjisiVarMi', label: 'İşyeri Üzerinde Güneş Enerjisi Var mı?', type: 'radio' }
    ],
    teminatlar: [
      { name: 'dekarsoyanTeminat', label: 'Dekorasyon Teminat Bedeli', type: 'number' },
      { name: 'demirbasTeminat', label: 'Demirbaş Teminat Bedeli', type: 'number' },
      { name: 'emteaBedel', label: 'Emtea Bedeli', type: 'number' },
      { name: 'ucuncuSahisMal', label: '3. Şahıs Malı', type: 'number' },
      { name: 'makineBedel', label: 'Makine Bedeli', type: 'number' },
      { name: 'elektronikCihazBedel', label: 'Elektronik Cihaz Bedeli', type: 'number' },
      { name: 'makineKirilmasi', label: 'Makine Kırılması İsteniyor mu?', type: 'radio' },
      { name: 'elektronikCihazAriza', label: 'Elektronik Cihaz Arızası İsteniyor mu?', type: 'radio' },
      { name: 'isverenMaliMesuliyet', label: 'İşveren Mali Mesuliyet İsteniyor mu?', type: 'radio' },
      { name: 'isverenMaliMesuliyetAciklama', label: 'İşveren Mali Mesuliyet Açıklama', type: 'text', dependency: 'isverenMaliMesuliyet', showIf: 'Evet' },
      { name: 'yanginMaliMesuliyet', label: 'Yangın Mali Mesuliyet İsteniyor mu?', type: 'radio' },
      { name: 'yanginMaliMesuliyetAciklama', label: 'Yangın Mali Mesuliyet Açıklama', type: 'text', dependency: 'yanginMaliMesuliyet', showIf: 'Evet' },
      { name: 'depremTeminati', label: 'Deprem Teminatı İsteniyor mu?', type: 'radio' },
      { name: 'depremTeminatiAciklama', label: 'Deprem Teminatı Açıklama', type: 'text', dependency: 'depremTeminati', showIf: 'Evet' },
      { name: 'camTeminati', label: 'Cam Teminatı', type: 'number' },
      { name: 'izolasyonEksikligi', label: 'İzolasyon Eksikliği Teminatı', type: 'number' },
      { name: 'tenteVarMi', label: 'Tente Var mı?', type: 'radio' },
      { name: 'tenteAciklama', label: 'Tente Açıklama', type: 'text', dependency: 'tenteVarMi', showIf: 'Evet' },
      { name: 'reklamTabelaVarMi', label: 'Reklam Tabelası Var mı?', type: 'radio' },
      { name: 'reklamTabelaAciklama', label: 'Reklam Tabelası Açıklama', type: 'text', dependency: 'reklamTabelaVarMi', showIf: 'Evet' },
      { name: 'oncekiSigorta', label: 'Daha Önce Sigorta Yaptırdınız mı?', type: 'radio' },
      { name: 'oncekiSigortaAciklama', label: 'Önceki Sigorta Açıklama', type: 'text', dependency: 'oncekiSigorta', showIf: 'Evet' },
      { name: 'hasarVarMi', label: 'Şu Ana Kadar Hiç Hasar Oldu mu?', type: 'radio' },
      { name: 'hasarAciklama', label: 'Hasar Açıklama', type: 'text', dependency: 'hasarVarMi', showIf: 'Evet' },
      { name: 'hasarTeminatlar', label: 'Hasar Olduysa Hangi Teminatlardan Hasar Aldınız?', type: 'text', dependency: 'hasarVarMi', showIf: 'Evet' }
    ],
    notlar: [
      { name: 'notlar', label: 'Notlar', type: 'textarea' }
    ]
  };

  const QuestionInput = ({ label, name, type, dependency, showIf }) => {
    const watchedValue = dependency ? watch(dependency) : null;
    const isVisible = !dependency || watchedValue === showIf;

    if (!isVisible) return null;

    return (
      <Controller
        name={name}
        control={control}
        render={({ field }) => (
          <div className="mb-4">
            <label className="block mb-2">{label}</label>
            
            {type === 'radio' ? (
              <div className="space-x-4">
                <label className="inline-flex items-center">
                  <input
                    type="radio"
                    value="Evet"
                    checked={field.value === "Evet"}
                    onChange={(e) => field.onChange(e.target.value)}
                    className="mr-2"
                  />
                  Evet
                </label>
                <label className="inline-flex items-center">
                  <input
                    type="radio"
                    value="Hayır"
                    checked={field.value === "Hayır"}
                    onChange={(e) => field.onChange(e.target.value)}
                    className="mr-2"
                  />
                  Hayır
                </label>
              </div>
            ) : type === 'textarea' ? (
              <textarea
                {...field}
                className="w-full p-2 border rounded"
                rows="3"
              />
            ) : (
              <input
                {...field}
                type={type}
                className="w-full p-2 border rounded"
              />
            )}
          </div>
        )}
      />
    );
  };


// generatePDF fonksiyonunu şu şekilde değiştirin
const generatePDF = (data) => {
  const content = `
    <div style="font-family: Arial, sans-serif; padding: 25px;">
      <h1 style="text-align: center; font-size: 20px; margin-bottom: 25px;">İŞYERİ TEKLİF FORMU</h1>
      
      <div style="display: flex; justify-content: space-between;">
        <!-- Sol Kolon -->
        <div style="width: 48%;">
          <h2 style="font-size: 12px; font-weight: bold; margin-bottom: 12px;">GENEL BİLGİLER</h2>
          <div style="font-size: 13px; line-height: 1.5;">
            <p>TC/Vergi No: ${data.tcVergiNo || ''}</p>
            <p>Yetkili Kişi: ${data.yetkiliKisi || ''}</p>
            <p>Tel No: ${data.yetkiliTel || ''}</p>
            <p>Faaliyet: ${data.faaliyetKonusu || ''}</p>
            <p>Adres: ${data.acikAdres || ''}</p>
            <p>İşyeri Durumu: ${data.isyeriDurum || ''}</p>
            <p>Metrekare: ${data.metrekare || ''}</p>
            <p>Konut Var mı: ${data.konutVarMi || ''}</p>
            <p>Bodrum Var mı: ${data.bodrumVarMi || ''}</p>
            <p>Bina Kat: ${data.binaKat || ''}</p>
            <p>Bina Yaşı: ${data.binaYas || ''}</p>
            <p>Sel Riski: ${data.selRiski || ''}</p>
            <p>Çalışan Sayısı: ${data.calisanSayisi || ''}</p>
            <p>DASK: ${data.daskDurum || ''}</p>
          </div>
          
          <h2 style="font-size: 12px; font-weight: bold; margin-top: 15px; margin-bottom: 10px;">GÜVENLİK ÖNLEMLERİ</h2>
          <div style="font-size: 13px; line-height: 1.5;">
            <p>Alarm: ${data.alarmVarMi || ''} ${data.alarmVarMi === 'Evet' ? `(${data.alarmAciklama || ''})` : ''}</p>
            <p>Kamera: ${data.kameraVarMi || ''}</p>
            <p>Kepenk: ${data.kepenkVarMi || ''}</p>
            <p>Bekçi: ${data.bekciVarMi || ''}</p>
          </div>
        </div>
        
        <!-- Sağ Kolon -->
        <div style="width: 48%;">
          <h2 style="font-size: 12px; font-weight: bold; margin-bottom: 10px;">YANGIN ÖNLEMLERİ</h2>
          <div style="font-size: 13px; line-height: 1.5;">
            <p>Yangın Tüpü: ${data.yanginTupuVarMi || ''} ${data.yanginTupuVarMi === 'Evet' ? `(${data.yanginTupuAciklama || ''})` : ''}</p>
            <p>Duman Dedektörü: ${data.dumaDedektoruVarMi || ''}</p>
            <p>Yangın Dolabı: ${data.yanginDolabiVarMi || ''}</p>
            <p>Sprinkler: ${data.sprinklerVarMi || ''}</p>
            <p>Yangın Alarmı: ${data.yanginAlarmiVarMi || ''}</p>
            <p>Su Deposu: ${data.suDeposuVarMi || ''}</p>
            <p>Jeneratör: ${data.jeneratorVarMi || ''}</p>
            <p>Güneş Enerjisi: ${data.gunesEnerjisiVarMi || ''}</p>
          </div>
          
          <h2 style="font-size: 12px; font-weight: bold; margin-top: 15px; margin-bottom: 10px;">TEMİNATLAR</h2>
          <div style="font-size: 13px; line-height: 1.5;">
            <p>Dekorasyon: ${data.dekarsoyanTeminat || ''}</p>
            <p>Demirbaş: ${data.demirbasTeminat || ''}</p>
            <p>Emtea: ${data.emteaBedel || ''}</p>
            <p>3.Şahıs Malı: ${data.ucuncuSahisMal || ''}</p>
            <p>Makine: ${data.makineBedel || ''}</p>
            <p>Elektronik: ${data.elektronikCihazBedel || ''}</p>
            <p>Makine Kırılması: ${data.makineKirilmasi || ''}</p>
            <p>Elektronik Arıza: ${data.elektronikCihazAriza || ''}</p>
            <p>İşveren MM: ${data.isverenMaliMesuliyet || ''} ${data.isverenMaliMesuliyet === 'Evet' ? `(${data.isverenMaliMesuliyetAciklama || ''})` : ''}</p>
            <p>Yangın MM: ${data.yanginMaliMesuliyet || ''} ${data.yanginMaliMesuliyet === 'Evet' ? `(${data.yanginMaliMesuliyetAciklama || ''})` : ''}</p>
            <p>Deprem: ${data.depremTeminati || ''} ${data.depremTeminati === 'Evet' ? `(${data.depremTeminatiAciklama || ''})` : ''}</p>
            <p>Cam: ${data.camTeminati || ''}</p>
            <p>İzolasyon: ${data.izolasyonEksikligi || ''}</p>
            <p>Tente: ${data.tenteVarMi || ''} ${data.tenteVarMi === 'Evet' ? `(${data.tenteAciklama || ''})` : ''}</p>
            <p>Reklam Tabelası: ${data.reklamTabelaVarMi || ''} ${data.reklamTabelaVarMi === 'Evet' ? `(${data.reklamTabelaAciklama || ''})` : ''}</p>
          </div>
        </div>
      </div>
      
<!-- Alt Bölüm -->
<div style="margin-top: 20px;">
  <h2 style="font-size: 12px; font-weight: bold; margin-bottom: 10px;">SİGORTA VE HASAR BİLGİLERİ</h2>
  <div style="font-size: 10px; line-height: 1.5;">
    <p>Önceki Sigorta: ${data.oncekiSigorta || ''} ${data.oncekiSigorta === 'Evet' ? `(${data.oncekiSigortaAciklama || ''})` : ''}</p>
    <p>Hasar Durumu: ${data.hasarVarMi || ''} ${data.hasarVarMi === 'Evet' ? `
      <br>Açıklama: ${data.hasarAciklama || ''}
      <br>Teminatlar: ${data.hasarTeminatlar || ''}` : ''}
    </p>
  </div>
  
  <h2 style="font-size: 12px; font-weight: bold; margin-top: 15px; margin-bottom: 10px;">NOTLAR</h2>
  <p style="font-size: 10px; line-height: 1.5;">${data.notlar || ''}</p>
</div>

<!-- İmza -->
<div style="margin-top: 30px; text-align: right;">
  <p style="font-size: 10px;">Kaşe/İmza</p>
  <div style="border: 1px solid black; width: 150px; height: 60px; display: inline-block;"></div>
</div>

<!-- Logo -->
<div style="margin-top: 50px; margin-bottom: 20px; text-align: center;">
  <img src="/kobilogo.gif" style="width: 250px; height: auto; opacity: 0.8;" />
</div>

  `;

  const opt = {
    margin: 10,
    filename: 'isyeri-teklif.pdf',
    image: { type: 'jpeg', quality: 0.98 },
    html2canvas: { scale: 2 },
    jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait' }
  };

  html2pdf().set(opt).from(content).save();
};

  return (
    <div className="max-w-4xl mx-auto p-6">
      <h1 className="text-2xl font-bold mb-6">İşyeri Teklif Formu</h1>
      
      <form onSubmit={handleSubmit(generatePDF)}>
        {Object.entries(questions).map(([category, items]) => (
          <div key={category} className="mb-8">
            <h2 className="text-xl font-semibold mb-4">
              {category === 'genelBilgiler' ? 'Genel Bilgiler' :
               category === 'guvenlikOnlemleri' ? 'Güvenlik Önlemleri' :
               category === 'yanginOnlemleri' ? 'Yangın Önlemleri' :
               category === 'teminatlar' ? 'Teminatlar' : 'Notlar'}
            </h2>
            
            <div className="space-y-4 bg-white p-4 rounded shadow">
              {items.map((item) => (
                <QuestionInput
                  key={item.name}
                  {...item}
                />
              ))}
            </div>
          </div>
        ))}

        <button
          type="submit"
          className="bg-blue-500 text-white px-6 py-2 rounded hover:bg-blue-600"
        >
          PDF Oluştur
        </button>
      </form>
    </div>
  );
};

export default IsyeriTeklif;