import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { Shield, Home, User, Car, Wrench, Star, TrendingUp, TrendingDown, Minus, Building, Heart, FileText } from 'lucide-react';



const TableCard = ({ title, children }) => (
  <div className="bg-white shadow-md rounded-lg p-4 mb-4">
    <h3 className="text-lg font-semibold mb-2">{title}</h3>
    {children}
  </div>
);

const ChangeIndicator = ({ currentValue, pastValue, viewType = 'count' }) => {
  const calculateChange = () => {
    // Adet hesaplaması
    if (viewType === 'count') {
      if (!pastValue || pastValue === 0) {
        return currentValue > 0 ? 100 : 0;
      }
      return ((currentValue - pastValue) / pastValue) * 100;
    }
    
    // Tutar hesaplaması
    else {
      if (!pastValue || pastValue === 0) {
        return currentValue > 0 ? 100 : 0;
      }
      const current = parseFloat(currentValue) / 100;
      const past = parseFloat(pastValue);
      return ((current - past) / past) * 100;
    }
  };

  const change = calculateChange();
  
  return (
    <div className="flex items-center gap-1 justify-center">
      {change > 0 ? (
        <TrendingUp size={14} className="text-green-500" />
      ) : change < 0 ? (
        <TrendingDown size={14} className="text-red-500" />
      ) : (
        <Minus size={14} className="text-gray-500" />
      )}
      <span className={`text-xs font-medium ${
        change > 0 ? 'text-green-600' : 
        change < 0 ? 'text-red-600' : 
        'text-gray-600'
      }`}>
        {Math.abs(change).toFixed(1)}%
      </span>
    </div>
  );
};

const ProductDisplay = ({ type, currentCount = 0, pastCount = 0, currentAmount = 0, pastAmount = 0 }) => {
  const icons = {
    'Dask': Shield,
    'Trafik Sigortası': Car,
    'Konut Sigortası': Home,
    'Yol Yardım': Wrench,
    'İMM': FileText,
    'İşyeri Sigortası': Building,
    'Sağlık Sigortası': Heart,
    'Kasko': Car,
    'Ferdi Kaza': User
  };

  const Icon = icons[type];

  const formatAmount = (value, isPastValue = false) => {
    const numberValue = isPastValue ? 
      parseFloat(value) : 
      parseFloat(value) / 100;
    
    return new Intl.NumberFormat('tr-TR', {
      style: 'currency',
      currency: 'TRY',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    }).format(numberValue)
      .replace('₺', '')
      .replace(/\s+/g, '')
      .trim() + ' TL';
  };
  
  return (
    <div className="flex flex-col items-center space-y-4 py-2">
      {/* Adet Bölümü */}
      <div className="border-b pb-2 w-full">
        <div className="text-xs font-medium text-gray-500 mb-1 text-center">Adet</div>
        <div className="flex flex-col items-center space-y-1">
          <div className="flex items-center gap-1">
            <Icon size={16} className="text-blue-600" />
            <span className="text-sm font-medium text-blue-600">
              {currentCount}
            </span>
          </div>
          
          <div className="flex items-center gap-1">
            <Icon size={16} className="text-gray-400" />
            <span className="text-sm font-medium text-gray-400">
              {pastCount}
            </span>
          </div>
    
          <ChangeIndicator 
            currentValue={currentCount} 
            pastValue={pastCount} 
            viewType="count"
          />
        </div>
      </div>

      {/* Tutar Bölümü */}
      <div className="w-full">
        <div className="text-xs font-medium text-gray-500 mb-1 text-center">Tutar</div>
        <div className="flex flex-col items-center space-y-1">
          <div className="flex items-center gap-1">
            <Icon size={16} className="text-blue-600" />
            <span className="text-sm font-medium text-blue-600">
              {formatAmount(currentAmount, false)}
            </span>
          </div>
          
          <div className="flex items-center gap-1">
            <Icon size={16} className="text-gray-400" />
            <span className="text-sm font-medium text-gray-400">
              {formatAmount(pastAmount, true)}
            </span>
          </div>
    
          <ChangeIndicator 
            currentValue={currentAmount} 
            pastValue={pastAmount} 
            viewType="amount"
          />
        </div>
      </div>
    </div>
  );
};

const ViewTypeSwitch = ({ viewType, onChange }) => (
  <div className="flex items-center gap-2 bg-gray-100 p-1 rounded-lg">
    <button
      onClick={() => onChange('count')}
      className={`px-3 py-1 rounded ${
        viewType === 'count'
          ? 'bg-white text-blue-600 shadow'
          : 'text-gray-600'
      }`}
    >
      Adet
    </button>
    <button
      onClick={() => onChange('amount')}
      className={`px-3 py-1 rounded ${
        viewType === 'amount'
          ? 'bg-white text-blue-600 shadow'
          : 'text-gray-600'
      }`}
    >
      Tutar
    </button>
  </div>
);

const CompanyBalance = () => {
  const navigate = useNavigate();
  const [data, setData] = useState({ current: {}, past: {} });
  const [loading, setLoading] = useState(true);
  const [selectedMonth, setSelectedMonth] = useState(new Date().getMonth() + 1);
  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());
  const [viewType, setViewType] = useState('count');

  const priorityCompanies = [ 'Ak Sigorta', 'Axa Sigorta', 'Anadolu Sigorta', 'Ana Sigorta', 'Allianz Sigorta', 'Doğa Sigorta', 'Sompo Sigorta', 'HDI Sigorta', 'Magdeburger Sigorta', 'Türkiye Sigorta', 'Türkiye Katılım Sigorta', 'Mapfre Sigorta', 'Neova Sigorta', 'Quick Sigorta', 'Ray Sigorta', 'Hepiyi Sigorta', 'GNC Yol Yardım' ];

  const productsList = [
    { key: 'Trafik Sigortası', label: 'Trafik' },
    { key: 'Kasko', label: 'Kasko' },
    { key: 'Konut Sigortası', label: 'Konut' },
    { key: 'Yol Yardım', label: 'Yol Yardım' },
    { key: 'İMM', label: 'İMM' },
    { key: 'İşyeri Sigortası', label: 'İşyeri' },
    { key: 'Sağlık Sigortası', label: 'Sağlık' },
    { key: 'Dask', label: 'DASK' },
    { key: 'Ferdi Kaza', label: 'Ferdi Kaza' }
  ];

  const groupCompaniesByPriority = (data) => {
    const priorityData = { current: {}, past: {} };
    const otherData = { current: {}, past: {} };

    Object.entries(data.current || {}).forEach(([company, products]) => {
      const normalizedCompanyName = company.trim();
      if (priorityCompanies.some(pc => pc.toLowerCase() === normalizedCompanyName.toLowerCase())) {
        priorityData.current[company] = products;
      } else {
        otherData.current[company] = products;
      }
    });

    Object.entries(data.past || {}).forEach(([company, products]) => {
      const normalizedCompanyName = company.trim();
      if (priorityCompanies.some(pc => pc.toLowerCase() === normalizedCompanyName.toLowerCase())) {
        priorityData.past[company] = products;
      } else {
        otherData.past[company] = products;
      }
    });

    return { priorityData, otherData };
  };

  const fetchData = async () => {
    try {
      const response = await axios.get(
        `https://centalyayanimda.com/get_company_balance.php?month=${selectedMonth}&year=${selectedYear}&viewType=${viewType}`
      );
      setData(response.data || { current: {}, past: {} });
    } catch (error) {
      console.error('Veri alınırken hata oluştu:', error);
      setData({ current: {}, past: {} });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [selectedMonth, selectedYear, viewType]);

  const months = [
    { value: 1, label: 'Ocak' },
    { value: 2, label: 'Şubat' },
    { value: 3, label: 'Mart' },
    { value: 4, label: 'Nisan' },
    { value: 5, label: 'Mayıs' },
    { value: 6, label: 'Haziran' },
    { value: 7, label: 'Temmuz' },
    { value: 8, label: 'Ağustos' },
    { value: 9, label: 'Eylül' },
    { value: 10, label: 'Ekim' },
    { value: 11, label: 'Kasım' },
    { value: 12, label: 'Aralık' }
  ];

  const years = Array.from(
    { length: 5 },
    (_, i) => new Date().getFullYear() - i
  );

  const renderCompanyRow = (company, currentProducts = {}, pastProducts = {}, isPriority = false) => (
    <tr key={company} className="hover:bg-gray-50">
      <td className="sticky left-0 z-10 bg-white px-6 py-4 whitespace-nowrap">
        <div className="flex items-center gap-2">
          {isPriority && <Star size={16} className="text-yellow-400 fill-yellow-400" />}
          <span className="text-sm font-medium text-gray-900">{company}</span>
        </div>
      </td>
      {productsList.map(({ key }) => (
        <td key={key} className="px-6 py-4 whitespace-nowrap">
          <ProductDisplay 
            type={key}
            currentCount={Number(currentProducts[`${key}_count`]) || 0}
            pastCount={Number(pastProducts[`${key}_count`]) || 0}
            currentAmount={Number(currentProducts[`${key}_amount`]) || 0}
            pastAmount={Number(pastProducts[`${key}_amount`]) || 0}
          />
        </td>
      ))}
    </tr>
  );

  if (loading) {
    return (
      <div className="flex justify-center items-center h-full">
        <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-blue-700"></div>
      </div>
    );
  }

  const { priorityData, otherData } = groupCompaniesByPriority(data);

  return (
    <div className="space-y-6">
      <div className="flex justify-between items-center">
        <div className="flex items-center gap-4">
          <h2 className="text-3xl font-semibold text-gray-800">Şirket Dağılımları</h2>
          <button
            onClick={() => navigate('/company-balance-detail')}
            className="px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition-colors"
          >
            Detay
          </button>
        </div>
        
        <div className="flex items-center gap-4">
          <ViewTypeSwitch viewType={viewType} onChange={setViewType} />
          <select
            className="border rounded p-2"
            value={selectedMonth}
            onChange={(e) => setSelectedMonth(Number(e.target.value))}
          >
            {months.map(month => (
              <option key={month.value} value={month.value}>
                {month.label}
              </option>
            ))}
          </select>

          <select
            className="border rounded p-2"
            value={selectedYear}
            onChange={(e) => setSelectedYear(Number(e.target.value))}
          >
            {years.map(year => (
              <option key={year} value={year}>
                {year}
              </option>
            ))}
          </select>
        </div>
      </div>

      <TableCard title={`Şirketlere Göre Ürün Dağılımı (${viewType === 'count' ? 'Adet' : 'Tutar'} Bazında)`}>
 <div className="relative">
   <div className="overflow-x-auto overflow-y-auto max-h-[70vh]">
     <table className="min-w-full divide-y divide-gray-200">
       <thead className="bg-gray-50">
         <tr>
           <th className="sticky top-0 left-0 z-20 bg-gray-50 px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
             Şirket
           </th>
           {productsList.map(({ label }) => (
             <th key={label} className="sticky top-0 z-10 bg-gray-50 px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">
               {label}
               <div className="text-xxs font-normal text-blue-600">{selectedYear}</div>
               <div className="text-xxs font-normal text-gray-400">{selectedYear - 1}</div>
             </th>
           ))}
         </tr>
       </thead>
       <tbody className="bg-white divide-y divide-gray-200">
         {priorityCompanies.map(company => {
           const normalizedCompany = Object.keys(priorityData.current).find(
             key => key.toLowerCase() === company.toLowerCase()
           );
           const pastNormalizedCompany = Object.keys(priorityData.past).find(
             key => key.toLowerCase() === company.toLowerCase()
           );
           return renderCompanyRow(
             company,
             normalizedCompany ? priorityData.current[normalizedCompany] : {},
             pastNormalizedCompany ? priorityData.past[pastNormalizedCompany] : {},
             true
           );
         })}
         
         {Object.keys(otherData.current).length > 0 && (
  <tr>
    <td colSpan={productsList.length + 1} className="sticky left-0 px-6 py-4">
      <div className="border-t-4 border-gray-300"></div> {/* border-t -> border-t-4 olarak değiştirildi */}
    </td>
  </tr>
)}

         {Object.keys(otherData.current).map(company => renderCompanyRow(
           company,
           otherData.current[company],
           otherData.past[company] || {},
           false
         ))}
       </tbody>
     </table>
   </div>
 </div>
</TableCard>
    </div>
  );
};

export default CompanyBalance;