import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Confetti from 'react-confetti';
import { 
  MdOutlineEmojiEvents,
  MdDiamond,
  MdStars,
  MdWorkspacePremium,
  MdMilitaryTech,
  MdLeaderboard  // Sadece bir kere import edilmeli
} from 'react-icons/md';
import { FaMedal, FaStar } from 'react-icons/fa';




const productGroups = [
  'Trafik Sigortası',
  'Kasko',
  'Sağlık Sigortası',
  'Konut Sigortası',
  'Dask',
  'Ferdi Kaza',
  'Yol Yardım',
  'İMM'
];




const months = [
  { value: 1, label: 'Ocak' },
  { value: 2, label: 'Şubat' },
  { value: 3, label: 'Mart' },
  { value: 4, label: 'Nisan' },
  { value: 5, label: 'Mayıs' },
  { value: 6, label: 'Haziran' },
  { value: 7, label: 'Temmuz' },
  { value: 8, label: 'Ağustos' },
  { value: 9, label: 'Eylül' },
  { value: 10, label: 'Ekim' },
  { value: 11, label: 'Kasım' },
  { value: 12, label: 'Aralık' }
];

const years = Array.from(
  { length: 5 },
  (_, i) => new Date().getFullYear() - i
);

const Hedefler = () => {
  const [goals, setGoals] = useState([]);
  const [performanceGoal, setPerformanceGoal] = useState(null);
  const [showConfetti, setShowConfetti] = useState(false);
  const [starProduct, setStarProduct] = useState(null);
  const [isDraggingOver, setIsDraggingOver] = useState(false);
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [starProductDetails, setStarProductDetails] = useState(null);
  const [userPoints, setUserPoints] = useState(null);
  const [selectedMonth, setSelectedMonth] = useState(new Date().getMonth() + 1);
const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());

  const formatCurrency = (value) => {
    const numberValue = parseFloat(value) / 100;
    return numberValue.toLocaleString('tr-TR', {
      style: 'currency',
      currency: 'TRY',
      minimumFractionDigits: 2,
    }).replace('₺', '').trim();
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        console.log(`Fetching data for month: ${selectedMonth}, year: ${selectedYear}`); // Debug log

        const [hedeflerResponse, starResponse, pointsResponse] = await Promise.all([
          axios.get(`https://centalyayanimda.com/hedefler.php?month=${selectedMonth}&year=${selectedYear}`, {
            withCredentials: true
          }),
          axios.get('https://centalyayanimda.com/get_star_product.php', {
            withCredentials: true
          }),
          // Burada URL'ye parametreleri doğru şekilde ekliyoruz
          axios.get(`https://centalyayanimda.com/get_user_points.php?month=${selectedMonth}&year=${selectedYear}`, {
            withCredentials: true
          })
        ]);
  
        // Hedefleri güncelle
        if (hedeflerResponse.data.success) {
          setGoals(hedeflerResponse.data.goals);
          setPerformanceGoal(hedeflerResponse.data.performanceGoal);
          if (hedeflerResponse.data.performanceGoal.current_premium >= hedeflerResponse.data.performanceGoal.premium_goal) {
            setShowConfetti(true);
          }
        }
  
        // Yıldız ürünü güncelle
        if (starResponse.data.success && starResponse.data.product_name) {
          setStarProduct(starResponse.data.product_name);
          if (hedeflerResponse.data.success) {
            const productDetails = hedeflerResponse.data.goals.find(
              goal => goal.product_name === starResponse.data.product_name
            );
            setStarProductDetails(productDetails);
          }
        }
  
        // Puanları güncelle
        if (pointsResponse.data.success) {
          // State'i temizle ve yeni değerleri set et
          setUserPoints(null); // Önce state'i temizle
          setTimeout(() => {
            setUserPoints(pointsResponse.data); // Sonra yeni değerleri set et
          }, 0);
        }
  
      } catch (error) {
        console.error('Veri yüklenirken bir hata oluştu:', error);
      }
    };
  
    fetchData();
  }, [selectedMonth, selectedYear]);


  const calculateProgress = (current, total) => {
    if (!total) return 0;
    return Math.min((current / total) * 100, 100);
  };

  const handleDragStart = (e, product) => {
    e.dataTransfer.setData('text/plain', product);
    e.target.style.opacity = '0.4';
  };

  const handleDragEnd = (e) => {
    e.target.style.opacity = '1';
    setIsDraggingOver(false);
  };

  const handleDragOver = (e) => {
    e.preventDefault();
    setIsDraggingOver(true);
  };

  const handleDragLeave = () => {
    setIsDraggingOver(false);
  };

  const handleDrop = async (e) => {
    e.preventDefault();
    const droppedProduct = e.dataTransfer.getData('text/plain');
    setIsDraggingOver(false);

    try {
      const response = await axios.post(
        'https://centalyayanimda.com/update_star_product.php',
        { product_name: droppedProduct },
        { withCredentials: true }
      );

      if (response.data.success) {
        setStarProduct(droppedProduct);
        // Yıldız ürün detaylarını güncelle
        const productDetails = goals.find(goal => goal.product_name === droppedProduct);
        setStarProductDetails(productDetails);
        // Başarı mesajını göster
        setShowSuccessMessage(true);
        // 5 saniye sonra mesajı gizle
        setTimeout(() => {
          setShowSuccessMessage(false);
        }, 5000);
      }
    } catch (error) {
      console.error('Yıldız ürün güncellenirken hata:', error);
    }
  };

  const getLevelIcon = (level) => {
    switch(level) {
      case 'Kraliyet': return <MdStars size={40} className="text-purple-600" />;
      case 'Ruby': return <MdDiamond size={40} className="text-red-600" />;
      case 'Safir': return <MdDiamond size={40} className="text-blue-600" />;
      case 'Elmas': return <MdDiamond size={40} className="text-cyan-400" />;
      case 'Platin': return <MdWorkspacePremium size={40} className="text-gray-400" />;
      case 'Altın': return <MdMilitaryTech size={40} className="text-yellow-500" />;
      case 'Gümüş': return <MdMilitaryTech size={40} className="text-gray-300" />;
      case 'Bronz': return <MdMilitaryTech size={40} className="text-yellow-700" />;
      default: return null;
    }
  };

  return (
    <div className="container mx-auto p-6">
      {showConfetti && <Confetti recycle={false} numberOfPieces={500} />}

      {userPoints && (
  <>
    {/* Seviye Yol Haritası */}
    <div className="mb-4">
      <div className="bg-white rounded-lg p-6 shadow-lg">
        <div className="relative">
          {/* Bağlantı çizgisi */}
          <div className="absolute top-1/2 left-0 right-0 h-2 bg-gray-200 -translate-y-1/2 z-0" />
          
          {/* Seviyeler */}
          <div className="relative z-10 flex justify-between">
            {['Bronz', 'Gümüş', 'Altın', 'Platin', 'Elmas', 'Safir', 'Ruby', 'Kraliyet'].map((level) => {
              const isCurrentLevel = level === userPoints.current_level;
              const isCompletedLevel = false;
              
              return (
                <div 
                  key={level}
                  className={`flex flex-col items-center ${isCurrentLevel ? 'scale-110 transform' : ''}`}
                >
                  <div className={`
                    w-12 h-12 rounded-full flex items-center justify-center
                    ${isCurrentLevel ? 'ring-4 ring-blue-300' : ''}
                    ${isCompletedLevel ? 'bg-green-500' : 'bg-white border-2 border-gray-300'}
                  `}>
                    {getLevelIcon(level)}
                  </div>
                  <div className={`
                    mt-2 text-sm font-medium text-center
                    ${isCurrentLevel ? 'text-blue-600 font-bold' : 'text-gray-500'}
                  `}>
                    {level}
                  </div>
                  {isCurrentLevel && (
                    <div className="mt-1">
                      <FaStar className="text-yellow-400" size={16} />
                    </div>
                  )}
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>

    {/* Mevcut Seviye Kartı */}
    <div className="mb-8">
      <div className="bg-gradient-to-r from-blue-50 to-purple-50 rounded-lg p-6 shadow-lg">
        <div className="flex items-center gap-4 mb-4">
          {getLevelIcon(userPoints.current_level)}
          <div>
            <h3 className="text-2xl font-bold">{userPoints.current_level} Seviyesi</h3>
            <p className="text-gray-600">Toplam Puanınız: {Math.floor(userPoints.total_points)}</p>
          </div>
        </div>
        
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mt-4">
          <div className="p-4 bg-white rounded-lg shadow">
            <h4 className="font-semibold text-lg mb-2">Mevcut Prim</h4>
            <p className="text-2xl font-bold text-green-600">TL</p>
          </div>
          
          {userPoints.next_level && (
            <div className="p-4 bg-white rounded-lg shadow">
              <h4 className="font-semibold text-lg mb-2">Sonraki Seviye</h4>
              <p className="text-gray-600">{userPoints.next_level.name}</p>
              <p className="text-sm">Gereken Puan: {userPoints.next_level.points_needed}</p>
              <p className="text-sm text-green-600">Prim:  TL</p>
            </div>
          )}
        </div>
      </div>
    </div>
  </>
)}

      {/* Başarı Mesajı */}
      {showSuccessMessage && (
        <div className="fixed top-4 right-4 bg-green-500 text-white px-6 py-4 rounded-lg shadow-lg transition-all duration-500 z-50">
          <div className="flex items-center gap-2">
            <FaStar className="text-yellow-300" size={24} />
            <div>
              <h4 className="font-bold">Tebrikler!</h4>
              <p>Yıldız Ürün Seçimini Yaptın, Şimdi Sırada Gerçekleştirmek Kaldı. BAŞARILAR</p>
            </div>
          </div>
        </div>
      )}
      
      <div className="space-y-4 mb-6">
        {/* Yıldız Ürün Alanı */}
        <div 
          onDragOver={handleDragOver}
          onDragLeave={handleDragLeave}
          onDrop={handleDrop}
          className={`
            border-2 border-dashed rounded-lg p-6 
            flex items-center justify-center min-h-[80px]
            ${isDraggingOver ? 'border-yellow-500 bg-yellow-50' : 'border-yellow-200'}
          `}
        >
          {starProduct ? (
            <div className="flex items-center gap-2">
              <FaStar className="text-yellow-400" size={24} />
              <span className="font-semibold text-lg">{starProduct}</span>
              <span className="text-sm text-gray-500 ml-2">
                (Değiştirmek için başka bir ürünü sürükleyin)
              </span>
            </div>
          ) : (
            <div className="text-gray-500">
              Yıldız ürün seçmek için bir ürünü bu alana sürükleyin
            </div>
          )}
        </div>

        {/* Yıldız Ürün Detayları */}
        {starProductDetails && (
          <div className="bg-gradient-to-r from-yellow-50 to-orange-50 rounded-lg p-6 shadow-md">
            <div className="flex flex-col items-start gap-1 mb-4">
              <div className="flex items-center gap-2">
                <FaStar className="text-yellow-400" size={24} />
                <h3 className="text-xl font-bold">Yıldız Ürün Detayları</h3>
              </div>
              <div className="ml-8 text-lg text-blue-600 font-semibold">
                {starProductDetails.product_name}
              </div>
            </div>
            
            <div className="grid grid-cols-2 gap-6">
              {/* Adet Hedefi */}
              <div className="bg-white rounded-lg p-4 shadow-sm">
                <h4 className="text-lg font-semibold mb-2">Adet Hedefi</h4>
                <div className="bg-gray-200 h-3 rounded-full overflow-hidden mb-2">
                  <div
                    className="bg-green-500 h-full rounded-full transition-all duration-500"
                    style={{ width: `${calculateProgress(starProductDetails.current_units, starProductDetails.unit_goal)}%` }}
                  />
                </div>
                <div className="flex justify-between text-sm">
                  <span className="font-medium">Mevcut: {starProductDetails.current_units}</span>
                  <span className="font-medium">Hedef: {starProductDetails.unit_goal}</span>
                </div>
                <div className="mt-1 text-sm text-gray-600">
                  Kalan: {starProductDetails.remaining_units}
                </div>
              </div>

              {/* Prim Hedefi */}
              <div className="bg-white rounded-lg p-4 shadow-sm">
                <h4 className="text-lg font-semibold mb-2">Prim Hedefi</h4>
                <div className="bg-gray-200 h-3 rounded-full overflow-hidden mb-2">
                  <div
                    className="bg-blue-500 h-full rounded-full transition-all duration-500"
                    style={{ width: `${calculateProgress(starProductDetails.current_premium, starProductDetails.premium_goal)}%` }}
                  />
                </div>
                <div className="flex justify-between text-sm">
                  <span className="font-medium">Mevcut: {formatCurrency(starProductDetails.current_premium)}</span>
                  <span className="font-medium">Hedef: {formatCurrency(starProductDetails.premium_goal)}</span>
                </div>
                <div className="mt-1 text-sm text-gray-600">
                  Kalan: {formatCurrency(starProductDetails.remaining_premium)}
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
      <div className="flex justify-between items-center mb-6">
  <h2 className="text-4xl font-bold text-indigo-600 flex items-center gap-2">
    <MdLeaderboard size={30} /> Hedeflerim
  </h2>
  
  <div className="flex items-center gap-4">
    <select
      className="border rounded p-2"
      value={selectedMonth}
      onChange={(e) => setSelectedMonth(Number(e.target.value))}
    >
      {months.map(month => (
        <option key={month.value} value={month.value}>
          {month.label}
        </option>
      ))}
    </select>

    <select
      className="border rounded p-2"
      value={selectedYear}
      onChange={(e) => setSelectedYear(Number(e.target.value))}
    >
      {years.map(year => (
        <option key={year} value={year}>
          {year}
        </option>
      ))}
    </select>
  </div>
</div>

      <div className="grid grid-cols-3 gap-4">
        {goals.map((goal, index) => (
          <div 
            key={index}
            className="bg-white shadow-md rounded-lg p-4 relative hover:shadow-lg transition-shadow duration-300"
            draggable
            onDragStart={(e) => handleDragStart(e, goal.product_name)}
            onDragEnd={handleDragEnd}
          >
            <h3 className="text-lg font-semibold mb-2 flex items-center gap-2">
            {starProduct === goal.product_name ? (
  <FaStar className="text-yellow-400" size={20} />
) : (
  <MdMilitaryTech size={20} className="text-yellow-500" />
)}
              {goal.product_name}
            </h3>
            
            {/* Adet Hedefi */}
            <div className="mb-4">
              <h4 className="text-md font-semibold">Adet Hedefi</h4>
              <div className="bg-gray-200 h-3 rounded-full overflow-hidden mb-2">
                <div
                  className="bg-green-500 h-full rounded-full transition-all duration-500"
                  style={{ width: `${calculateProgress(goal.current_units, goal.unit_goal)}%` }}
                />
              </div>
              <p className="text-xs">
                {goal.current_units} / {goal.unit_goal}
              </p>
              <p className="text-xs text-gray-600">
                Kalan: {goal.remaining_units}
              </p>
            </div>

            {/* Prim Hedefi */}
            <div>
              <h4 className="text-md font-semibold">Prim Hedefi</h4>
              <div className="bg-gray-200 h-3 rounded-full overflow-hidden mb-2">
                <div
                  className="bg-blue-500 h-full rounded-full transition-all duration-500"
                  style={{ width: `${calculateProgress(goal.current_premium, goal.premium_goal)}%` }}
                />
              </div>
              <p className="text-xs">
                {formatCurrency(goal.current_premium)} / {formatCurrency(goal.premium_goal)}
              </p>
              <p className="text-xs text-gray-600">
                Kalan: {formatCurrency(goal.remaining_premium)}
              </p>
            </div>
          </div>
        ))}

        {performanceGoal && (
          <div className="bg-white shadow-md rounded-lg p-4 col-span-3 relative hover:shadow-lg transition-shadow duration-300">
<h3 className="text-lg font-semibold mb-2 flex items-center gap-2">
  <MdOutlineEmojiEvents size={20} className="text-yellow-500" /> Toplam Performans
</h3>
            <div className="bg-gray-200 h-5 rounded-full overflow-hidden mb-2">
              <div
                className="bg-red-600 h-full rounded-full transition-all duration-500"
                style={{ width: `${calculateProgress(performanceGoal.current_premium, performanceGoal.premium_goal)}%` }}
              />
            </div>
            <p className="text-sm">
              {formatCurrency(performanceGoal.current_premium)} / {formatCurrency(performanceGoal.premium_goal)}
            </p>
            <p className="text-sm text-gray-600">
              Kalan: {formatCurrency(performanceGoal.remaining_premium)}
            </p>
          </div>
        )}
      </div>


    </div>
  );
};

export default Hedefler;

//{userPoints.current_reward} //mevcut prim alanına eklenecek
//                 "text-sm text-green-600">Prim: {userPoints.next_level.reward} TL 
